import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const ListSuccessIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M9.25 5c0-.41.34-.75.75-.75h12a.75.75 0 0 1 0 1.5H10A.75.75 0 0 1 9.25 5ZM9.25 12c0-.41.34-.75.75-.75h12a.75.75 0 0 1 0 1.5H10a.75.75 0 0 1-.75-.75ZM9.25 19c0-.41.34-.75.75-.75h12a.75.75 0 0 1 0 1.5H10a.75.75 0 0 1-.75-.75ZM4 10.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM1.25 12a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM4 17.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM1.25 19a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM7.03 2.97c.3.3.3.77 0 1.06l-3 3c-.3.3-.77.3-1.06 0l-1.5-1.5a.75.75 0 0 1 1.06-1.06l.97.97 2.47-2.47c.3-.3.77-.3 1.06 0Z"
      fill="currentColor"
    />
  </svg>
);

ListSuccessIcon.propTypes = propTypes;
ListSuccessIcon.defaultProps = defaultProps;

export default React.memo(ListSuccessIcon);
