import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const MindMappingIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M1.25 12a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM4 10.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM19.25 3a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0ZM21 2.75a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5ZM19.25 12a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm1.75-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5ZM19.25 21a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm1.75-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5ZM9.25 3c0-.41.34-.75.75-.75h6a.75.75 0 0 1 0 1.5h-5.25v16.5H16a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75V3Z"
      fill="currentColor"
    />
    <path
      d="M5.25 12c0-.41.34-.75.75-.75h10a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
  </svg>
);

MindMappingIcon.propTypes = propTypes;
MindMappingIcon.defaultProps = defaultProps;

export default React.memo(MindMappingIcon);
