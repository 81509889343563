import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const BillIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M6 2.75a.25.25 0 0 0-.25.25v17.54l2.31-1.65a.75.75 0 0 1 .88 0L12 21.08l3.06-2.19a.75.75 0 0 1 .88 0l2.31 1.65V3a.25.25 0 0 0-.25-.25H6ZM4.25 3c0-.97.78-1.75 1.75-1.75h12c.97 0 1.75.78 1.75 1.75v19a.75.75 0 0 1-1.19.61l-3.06-2.19-3.06 2.19a.75.75 0 0 1-.88 0L8.5 20.42l-3.06 2.19A.75.75 0 0 1 4.25 22V3Z"
      fill="currentColor"
    />
    <path
      d="M8.25 11c0-.41.34-.75.75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM8.25 15c0-.41.34-.75.75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM8.25 7c0-.41.34-.75.75-.75h6a.75.75 0 0 1 0 1.5H9A.75.75 0 0 1 8.25 7Z"
      fill="currentColor"
    />
  </svg>
);

BillIcon.propTypes = propTypes;
BillIcon.defaultProps = defaultProps;

export default React.memo(BillIcon);
