import PropTypes from 'prop-types';

const headingTag = PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']);

const location = PropTypes.shape({
    state: PropTypes.shape({
        key: PropTypes.string,
    }),
});

const CustomPropTypes = {
  ...PropTypes,
  headingTag,
  location,
};

export default CustomPropTypes;
