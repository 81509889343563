import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const InboxIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M3.77 2.85c.07-.35.37-.6.73-.6h15c.36 0 .66.25.73.6l2.5 12a.75.75 0 0 1-1.46.3l-2.38-11.4H5.11l-2.38 11.4a.75.75 0 1 1-1.46-.3l2.5-12Z"
      fill="currentColor"
    />
    <path
      d="M1.25 15c0-.41.34-.75.75-.75h5.45c.34 0 .63.22.72.53l.75 2.47h6.16l.75-2.47c.1-.31.39-.53.72-.53H22c.41 0 .75.34.75.75v6.5c0 .41-.34.75-.75.75H2a.75.75 0 0 1-.75-.75V15Zm1.5.75v5h18.5v-5H17.1l-.75 2.47a.75.75 0 0 1-.71.53H8.36a.75.75 0 0 1-.71-.53l-.75-2.47H2.75ZM8.75 7c0-.41.34-.75.75-.75h5a.75.75 0 0 1 0 1.5h-5A.75.75 0 0 1 8.75 7ZM7.25 11c0-.41.34-.75.75-.75h8a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
  </svg>
);

InboxIcon.propTypes = propTypes;
InboxIcon.defaultProps = defaultProps;

export default React.memo(InboxIcon);
