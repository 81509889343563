import * as React from "react";

import ArrowRightIcon from '../components/icons/ArrowRightIcon';
import Button from '../components/Button';
import MiraLogo from '../components/MiraLogo';

const Header = () => (
  <header className="bg-white text-azure-100 py-4">
    <div className="container-lg flex justify-between items-center">
      <div>
        <MiraLogo className="w-16 md:w-36" />
      </div>
      <div>
        <Button to="https://www.miracommerce.com/" variant="primary" size="sm" className="items-center group">
          <span className="mr-4">
            To Mira Commerce
          </span>
          {' '}
          <ArrowRightIcon className="h-4 w-4 group-hover:translate-x-0.5 duration-200" />
        </Button>
      </div>
    </div>
  </header>
);

export default Header;
