import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const EqualizerIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M5.5 7.25c.41 0 .75.34.75.75v13a.75.75 0 0 1-1.5 0V8c0-.41.34-.75.75-.75ZM12 13.75c.41 0 .75.34.75.75V21a.75.75 0 0 1-1.5 0v-6.5c0-.41.34-.75.75-.75ZM12 2.25c.41 0 .75.34.75.75v6.5a.75.75 0 0 1-1.5 0V3c0-.41.34-.75.75-.75ZM18.5 2.25c.41 0 .75.34.75.75v13a.75.75 0 0 1-1.5 0V3c0-.41.34-.75.75-.75Z"
      fill="currentColor"
    />
    <path
      d="M2.25 5.5a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM5.5 3.75a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM8.75 12a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM12 10.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM15.25 18.5a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Zm3.25-1.75a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Z"
      fill="currentColor"
    />
  </svg>
);

EqualizerIcon.propTypes = propTypes;
EqualizerIcon.defaultProps = defaultProps;

export default React.memo(EqualizerIcon);
