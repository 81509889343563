import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const ShoppingBagIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M1.93 8.01a.75.75 0 0 1 .57-.26h19a.75.75 0 0 1 .74.87l-2.1 13a.75.75 0 0 1-.74.63H4.6a.75.75 0 0 1-.74-.63l-2.1-13a.75.75 0 0 1 .17-.6Zm1.45 1.24 1.86 11.5h13.52l1.86-11.5H3.38Z"
      fill="currentColor"
    />
    <path
      d="M5.75 8.5c0-3.67 2.74-6.75 6.25-6.75s6.25 3.08 6.25 6.75a.75.75 0 0 1-1.5 0c0-2.96-2.19-5.25-4.75-5.25S7.25 5.54 7.25 8.5a.75.75 0 0 1-1.5 0ZM8.5 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM9.62 16.08a.75.75 0 0 0-1.24.84L9 16.5l-.62.42.02.03a2.63 2.63 0 0 0 .23.28A4.59 4.59 0 0 0 12 18.75a4.45 4.45 0 0 0 3.6-1.8l.01-.02h.01v-.01L15 16.5l.62.42a.75.75 0 0 0-1.24-.84l-.02.03-.1.13A3.09 3.09 0 0 1 12 17.25a2.95 2.95 0 0 1-2.38-1.17ZM15.5 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="currentColor"
    />
  </svg>
);

ShoppingBagIcon.propTypes = propTypes;
ShoppingBagIcon.defaultProps = defaultProps;

export default React.memo(ShoppingBagIcon);
