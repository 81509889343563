import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const LinkedInIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <circle
      cx="6.57692"
      cy="5.57692"
      r="2.57692"
      stroke="white"
      strokeWidth="2"
    />
    <rect
      x="4"
      y="12.0193"
      width="5.15385"
      height="16.1058"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M14.3077 18.1394V28.1249H18.8173V19.1057C18.8173 17.4951 19.7836 16.2067 21.3942 16.2067C23.0048 16.2067 23.9711 17.8172 23.9711 19.1057V28.1249H28.4808V18.1394C28.4808 16.2067 26.2259 12.0192 21.3942 12.0192C16.5625 12.0192 14.3077 16.2067 14.3077 18.1394Z"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

LinkedInIcon.propTypes = propTypes;
LinkedInIcon.defaultProps = defaultProps;

export default React.memo(LinkedInIcon);
