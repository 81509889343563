import * as React from 'react';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  YoutubeIcon,
} from '../components/icons';
import Link from '../components/Link';
import MiraLogo from '../components/MiraLogo';

const Footer = () => {
  return (
    <footer className="bg-black text-white text-center">
      <div className="container-lg py-10">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <MiraLogo className="w-36" />
          <div className="flex gap-x-6 mt-8 lg:mt-0">
            <Link to="https://facebook.com/miraCommerceAgency">
              <FacebookIcon className="h-8 w-8" />
              <span className="sr-only">Mira Commerce Facebook page</span>
            </Link>
            <Link to="https://www.youtube.com/@miracommerce">
              <YoutubeIcon className="h-8 w-8" />
              <span className="sr-only">Mira Commerce Youtube channel</span>
            </Link>
            <Link to="https://www.linkedin.com/company/miracommerce">
              <LinkedInIcon className="h-8 w-8" />
              <span className="sr-only">Mira Commerce LinkedIn page</span>
            </Link>
            <Link to="https://www.instagram.com/miracommerceagency/">
              <InstagramIcon className="h-8 w-8" />
              <span className="sr-only">Mira Commerce Instagram page</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="container-lg py-2.5">
        <p className="typo-small">
          &copy; Copyright 2004 – 2023 Mira Commerce, Inc. All Rights Reserved |
          {' '}
          <Link to="https://www.miracommerce.com/privacy-policy/">
            Privacy Policy
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
