import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const LockIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M4 11.75a.25.25 0 0 0-.25.25v9c0 .14.11.25.25.25h16c.14 0 .25-.11.25-.25v-9a.25.25 0 0 0-.25-.25H4ZM2.25 12c0-.97.78-1.75 1.75-1.75h16c.97 0 1.75.78 1.75 1.75v9c0 .97-.78 1.75-1.75 1.75H4c-.97 0-1.75-.78-1.75-1.75v-9Z"
      fill="currentColor"
    />
    <path
      d="M12 2.75A4.25 4.25 0 0 0 7.75 7v4a.75.75 0 0 1-1.5 0V7a5.75 5.75 0 0 1 11.5 0v4a.75.75 0 0 1-1.5 0V7c0-2.35-1.9-4.25-4.25-4.25ZM12 14.25c.41 0 .75.34.75.75v3a.75.75 0 0 1-1.5 0v-3c0-.41.34-.75.75-.75Z"
      fill="currentColor"
    />
  </svg>
);

LockIcon.propTypes = propTypes;
LockIcon.defaultProps = defaultProps;

export default React.memo(LockIcon);
