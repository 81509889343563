import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const InstagramIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <path
      d="M22.6667 4H9.33333C6.38781 4 4 6.38781 4 9.33333V22.6667C4 25.6122 6.38781 28 9.33333 28H22.6667C25.6122 28 28 25.6122 28 22.6667V9.33333C28 6.38781 25.6122 4 22.6667 4Z"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M16 21.3333C18.9455 21.3333 21.3334 18.9455 21.3334 16C21.3334 13.0544 18.9455 10.6666 16 10.6666C13.0545 10.6666 10.6667 13.0544 10.6667 16C10.6667 18.9455 13.0545 21.3333 16 21.3333Z"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M23.3333 10C24.0697 10 24.6667 9.40309 24.6667 8.66671C24.6667 7.93033 24.0697 7.33337 23.3333 7.33337C22.597 7.33337 22 7.93033 22 8.66671C22 9.40309 22.597 10 23.3333 10Z"
      fill="white"
    />
  </svg>
);

InstagramIcon.propTypes = propTypes;
InstagramIcon.defaultProps = defaultProps;

export default React.memo(InstagramIcon);
