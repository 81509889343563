import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const FacebookIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <path
      d="M24 8.39983H20.8326H19.9914C19.2672 8.39983 18.6802 8.9869 18.6802 9.71108V14.3116H24L23.2209 19.3763H18.6802V28.6667H12.8301V19.3763H8V14.3116H12.7676L12.8301 9.50424L12.8212 8.63141C12.7919 5.73483 15.1163 3.36294 18.0129 3.33364C18.0306 3.33346 18.0483 3.33337 18.066 3.33337H24V8.39983Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

FacebookIcon.propTypes = propTypes;
FacebookIcon.defaultProps = defaultProps;

export default React.memo(FacebookIcon);
