import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const YoutubeIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <path
      d="M7.37555 6.9429C11.071 6.75872 13.9453 6.66663 15.9985 6.66663C18.0518 6.66663 20.9271 6.75873 24.6245 6.94295V6.94295C26.6258 7.04267 28.2446 8.60789 28.4115 10.6048C28.5806 12.6274 28.6651 14.4089 28.6651 15.9492C28.6651 17.5084 28.5785 19.3147 28.4052 21.3681H28.4052C28.2388 23.3402 26.6553 24.8941 24.6804 25.0232C21.5195 25.2299 18.6255 25.3333 15.9985 25.3333C13.3718 25.3333 10.4788 25.23 7.31962 25.0233V25.0233C5.34544 24.8942 3.76222 23.3413 3.59501 21.3699C3.41953 19.3011 3.33179 17.4942 3.33179 15.9492C3.33179 14.4227 3.41743 12.6406 3.58872 10.6029L3.58873 10.6029C3.75652 8.6068 5.37491 7.04261 7.37555 6.9429Z"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.9999 13.0727V18.9372C13.9999 19.2528 14.2558 19.5087 14.5714 19.5087C14.6834 19.5087 14.793 19.4757 14.8864 19.414L19.2848 16.5069C19.5481 16.3329 19.6205 15.9784 19.4465 15.7151C19.4049 15.6522 19.3512 15.5981 19.2886 15.556L14.8902 12.5985C14.6283 12.4224 14.2733 12.492 14.0972 12.7539C14.0338 12.8481 13.9999 12.9591 13.9999 13.0727Z"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

YoutubeIcon.propTypes = propTypes;
YoutubeIcon.defaultProps = defaultProps;

export default React.memo(YoutubeIcon);
