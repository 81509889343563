import classnames from 'classnames';
import React from 'react';

import Link from '../Link';

import PropTypes from '../../utils/PropTypes';
import warning from '../../utils/warning';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'reset', 'primary', 'secondary', 'accent']),
  size: PropTypes.oneOf(['default', 'reset', 'md', 'sm']),
  submit: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

const defaultProps = {
  children: null,
  className: null,
  variant: 'default',
  size: 'default',
  submit: false,
  onClick: null,
  to: null,
};

const Button = ({
  children,
  className,
  onClick,
  submit,
  variant,
  size,
  to,
  ...rest
}) => {
  const buttonClassName = classnames(
    className,
    'btn no-underline',
    {
      'bg-transparent': variant === 'default' && variant === 'reset',
      'btn-primary': variant === 'primary',
      'btn-secondary': variant === 'secondary',
      'btn-accent': variant === 'accent',
      'btn-reset': size === 'reset',
      'btn-md': size === 'md',
      'btn-sm': size === 'sm',
    },
  );

  warning(
    !(submit && onClick),
    'You provide onClick to a submit button. Please remove onClick handler or submit attribute',
  );

  if (submit) {
    return (
      <button type="submit" className={buttonClassName}>
        {children}
      </button>
    );
  }

  if (to) {
    return (
      <Link to={to} onClick={onClick} className={buttonClassName} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <button type="button" onClick={onClick} className={buttonClassName}>
      {children}
    </button>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
