import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const WaterfallsIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M2.25 3c0-.41.34-.75.75-.75h7c.41 0 .75.34.75.75v5.5c0 .41-.34.75-.75.75H3a.75.75 0 0 1-.75-.75V3Zm1.5.75v4h5.5v-4h-5.5ZM13.25 15.5c0-.41.34-.75.75-.75h7c.41 0 .75.34.75.75V21c0 .41-.34.75-.75.75h-7a.75.75 0 0 1-.75-.75v-5.5Zm1.5.75v4h5.5v-4h-5.5ZM13.25 3c0-.41.34-.75.75-.75h7c.41 0 .75.34.75.75v8.5c0 .41-.34.75-.75.75h-7a.75.75 0 0 1-.75-.75V3Zm1.5.75v7h5.5v-7h-5.5ZM2.25 12.5c0-.41.34-.75.75-.75h7c.41 0 .75.34.75.75V21c0 .41-.34.75-.75.75H3a.75.75 0 0 1-.75-.75v-8.5Zm1.5.75v7h5.5v-7h-5.5Z"
      fill="currentColor"
    />
  </svg>
);

WaterfallsIcon.propTypes = propTypes;
WaterfallsIcon.defaultProps = defaultProps;

export default React.memo(WaterfallsIcon);
