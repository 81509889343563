import * as React from 'react';
import ConsentManagementBar from './ConsentManagementBar';
import { LayoutProvider } from './Context';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, location }) => {
  return (
    <LayoutProvider location={location}>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
      <ConsentManagementBar />
    </LayoutProvider>
  );
};

export default Layout;
