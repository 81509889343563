import classnames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import isInternalLink from '../../utils/isInternalLink';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  partiallyActive: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

const defaultProps = {
  activeClassName: null,
  children: null,
  className: null,
  partiallyActive: false,
};

const FILE_LINK_PATTERN = /\.[0-9a-z]+$/i;
const UNDERLINE_CLASS_PATTERN = /(^|\s)underline-\S+/;
const DECORATION_CLASS_PATTERN = /(^|\s)decoration-((?!(slice|clone))\S)+/;

const Link = ({
  activeClassName,
  children,
  className,
  partiallyActive,
  to,
  ...rest
}) => {
  const classNames = classnames(
    className,
    {
      underline: !(className && className.includes('no-underline')),
      'decoration-font': !DECORATION_CLASS_PATTERN.test(className),
      'underline-widest': !UNDERLINE_CLASS_PATTERN.test(className),
    },
  );

  if (!isInternalLink(to)) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className={classNames} {...rest}>
        {children}
      </a>
    );
  }

  if (FILE_LINK_PATTERN.test(to)) {
    return (
      <a href={to} className={classNames} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={to}
      activeClassName={activeClassName}
      className={classNames}
      partiallyActive={partiallyActive}
      {...rest}
    >
      {children}
    </GatsbyLink>
  );
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
