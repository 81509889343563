import React from 'react';
import { useCookies } from 'react-cookie';
import Button from '../components/Button';
import Link from '../components/Link';

const ANALYTICS_COOKIE_NAME = 'gatsby-gdpr-google-analytics';
const ANALYTIVS_TRACKING_ID = 'G-GGQP7ZST82';

const ConsentManagementBar = () => {
  const [cookies, setCookie] = useCookies([ANALYTICS_COOKIE_NAME]);

  const handleDecline = () => {
    window[`ga-disable-${ANALYTIVS_TRACKING_ID}`] = true;
    setCookie(ANALYTICS_COOKIE_NAME, false, {
      path: '/',
      maxAge: 3000 * 24 * 60 * 60,
    });
  };

  const handleAccept = () => {
    setCookie(ANALYTICS_COOKIE_NAME, true, {
      path: '/',
      maxAge: 3000 * 24 * 60 * 60,
    });
  };

  if (cookies[ANALYTICS_COOKIE_NAME] !== undefined) {
    return (
      <></>
    );
  }

  return (
    <div className="bg-white fixed bottom-0 w-full shadow-xl">
      <div className="container-lg py-4 flex flex-wrap gap-4 justify-between items-center">
        <p className="typo-small">
          By using bigcommerceb2b.com you accept our use of cookies and agree to our
          {' '}
          <Link to="https://www.miracommerce.com/privacy-policy/" className="text-blue-400 no-underline">
            privacy policy
          </Link>
          .
        </p>
        <div className="flex items-center gap-4">
          <div>
            <Button onClick={handleAccept} variant="primary" size="sm">
              Accept
            </Button>
          </div>
          <div>
            <Button onClick={handleDecline} variant="secondary" size="sm">
              Decline
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsentManagementBar;
