import React from 'react';
import PropTypes from '../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const CycleIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M2.75 17.5c0-.41.34-.75.75-.75h3a.75.75 0 0 1 0 1.5H4.25v2.25a.75.75 0 0 1-1.5 0v-3ZM17.5 16.75c.41 0 .75.34.75.75v2.25h2.25a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75v-3c0-.41.34-.75.75-.75ZM20.5 2.75c.41 0 .75.34.75.75v3c0 .41-.34.75-.75.75h-3a.75.75 0 0 1 0-1.5h2.25V3.5c0-.41.34-.75.75-.75ZM2.75 3.5c0-.41.34-.75.75-.75h3c.41 0 .75.34.75.75v3a.75.75 0 0 1-1.5 0V4.25H3.5a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
    <path
      d="M7.13 3.23c.22.35.13.81-.22 1.04a9.24 9.24 0 0 0-4.06 9.12.75.75 0 0 1-1.48.22 10.74 10.74 0 0 1 4.72-10.6.75.75 0 0 1 1.04.22ZM3.23 16.87a.75.75 0 0 1 1.04.22 9.24 9.24 0 0 0 9.12 4.06.75.75 0 0 1 .22 1.48 10.74 10.74 0 0 1-10.6-4.72.75.75 0 0 1 .22-1.04ZM21.78 9.76c.4-.06.79.22.85.63a10.74 10.74 0 0 1-4.72 10.6.75.75 0 1 1-.82-1.26 9.24 9.24 0 0 0 4.06-9.12.75.75 0 0 1 .63-.85ZM12 2.75c-.47 0-.94.04-1.39.1a.75.75 0 0 1-.22-1.48 10.74 10.74 0 0 1 10.6 4.72.75.75 0 1 1-1.26.82A9.24 9.24 0 0 0 12 2.75Z"
      fill="currentColor"
    />
  </svg>
);

CycleIcon.propTypes = propTypes;
CycleIcon.defaultProps = defaultProps;

export default React.memo(CycleIcon);
